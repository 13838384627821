/**
Функция transformDate возвращает дату в формате гггг-мм-дд

@param {string} date - дата
@returns {string | null} возвращает дату в указанном формате или null если аргумент не передан

*/

export const transformDate = (date) => {
  if (date) {
    const [day, month, year] = date.split(".");
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  } else {
    return null
  }
};
