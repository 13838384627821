import { InfoList } from "../../../InfoList/ui/InfoList";
import { StatusLabel } from "../../../StatusLabel/ui/StatusLabel";
import styles from "./UsersList.module.scss";
import { useEffect, useState } from "react";
import cn from "classnames";
import { ModalWindow } from "../../../ModalWindow";
import { CreateUserForm } from "../../../Forms";
import { Preloader } from "../../../Preloader";
import { toast } from "react-toastify";

/**
Компонент, возвращающий список пользователей

Пропсы:
@param {array} usersData - массив, содержащий информацию о пользователях
@param {boolean} loading - флаг, говорящий о том, находится ли выполнение запроса в процессе или он уже выполнен, если значение true - значит запрос еще выполняется и на странице показывается прелоадер, если false, на странице показываются данные, которые вернул запрос
@param {string} error - переменная, хранящая текст ошибки, в случае если запрос возвращает ошибку
@returns {ReactElement} - возвращает компонент Infolist (элемент списка) с информацией о пользователях и модальное окно для редиктирования конкретного пользователя

*/

export const UsersList = ({ usersData, loading, error }) => {
  const [activeUserId, setActiveUserId] = useState("");
  const [accountsStatuses, setAccountsStatuses] = useState([]);

  useEffect(() => {
    const statuses = usersData
      .map((item) =>
        item.accounts.filter((account) => account.permissionStatus === false)
      )
      .flat();

    setAccountsStatuses(statuses);
  }, [usersData]);

  return (
    <ul className={styles.usersList}>
      {usersData.map((user, index) => (
        <div key={index}>
          <InfoList key={index} onClick={() => setActiveUserId(user?.id)}>
            <div className={styles.usersInner}>
              <div className={styles.userInfo}>
                {user?.accounts?.some(account => account.permissionStatus === false) ? (
                  <StatusLabel text="Работа запрещена" variant="negative" />
                ) : (
                  <StatusLabel text="Работа разрешена" variant="positive" />
                )}

                <span className={styles.userRole}>{user.userRole}</span>
                <div className={styles.userBio}>
                  <span>
                    {user?.lastName +
                      " " +
                      user?.firstName +
                      " " +
                      user?.patronymic}
                  </span>
                  <div className={styles.userContacts}>
                    <span>{user?.email}</span>
                    <span>{user?.phone}</span>
                  </div>
                </div>
              </div>
              <div className={styles.userEdsInfo}>
                <span
                  className={cn({
                    [styles.edsNone]: !user?.accounts?.accStatus,
                    [styles.signatureAcive]: user?.signaturesActive?.isActive,
                  })}
                >
                  {user?.signaturesActive?.isActive
                    ? "ЭЦП действует"
                    : "ЭЦП не действует"}
                  {/* {user?.signaturesActive?.connector} */}
                </span>
              </div>
            </div>
          </InfoList>
          {activeUserId === user?.id && (
            <ModalWindow show={activeUserId} setShowModal={setActiveUserId}>
              <CreateUserForm
                usersData={usersData}
                item={user}
                id={user.id}
                hideModal={setActiveUserId}
                userUuid={user.uuid}
              />
            </ModalWindow>
          )}
        </div>
      ))}
    </ul>
  );
};
