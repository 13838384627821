import ccpa from "crypto-pro-cadesplugin";
import { Button } from "../../Button";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import styles from "./CryptoTokenMassive.module.scss";

/**
Компонент для удаленного подписания токеном

Пропсы:
@param {array} documents - массив документов для подписания
@param {object} certificateInfo - информация о сертификате
@returns {JSX.Element} Возвращает компонент кнопки.
*/

export const CryptoTokenMassive = ({ documents, certificateInfo }) => {
  const { token } = useParams();
  const [convertedFiles, setConvertedFiles] = useState([]);
  const [isSigned, setIsSigned] = useState(false);
  const [error, setError] = useState("");
  const getDoccuments = process.env.REACT_APP_MASSIVE_SIGN_DOCUMENTS;
  const fullLink = getDoccuments + token + "/documents/";

  const addClientDocuments = async (body) => {
    try {
      const response = await fetch(fullLink, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });

      if (!response.ok) {
        throw new Error("Не удалось отправить документы");
      }

      const data = await response.json();

      setIsSigned(true);

      return data;
    } catch (error) {
      setError(error.message);
    }
  };

  /**
  Функция signatureDoc отвечает за подписание документа с помощью КриптоПро и при успешном подписании отправляет запрос на сервер с данными подписанного документа и подписью в формате base64
  
  @param {File} doc - файл для подписания
  @param {string} thumbprint - отпечаток выбранного сертификата
  */

  const signatureDoc = async (doc, thumbprint) => {
    const certsApi = await ccpa();
    const sign = await certsApi?.signBase64(thumbprint, doc?.file);
    setConvertedFiles((prev) => [
      ...prev,
      {
        id: doc?.signature_document_id,
        file: sign,
        document_uuid: doc?.document_uuid,
      },
    ]);
  };

  const signatureDocuments = async () => {
    await documents.forEach((document) => {
      signatureDoc(document, certificateInfo?.Thumbprint);
    });
  };

  useEffect(() => {
    if (
      convertedFiles.length > 0 &&
      convertedFiles.length === documents.length
    ) {
      addClientDocuments(convertedFiles);
    }
  }, [documents, convertedFiles]);

  return (
    <>
      {!isSigned ? (
        <Button variant="green" onClick={signatureDocuments}>
          Подписать все
        </Button>
      ) : error !== "" ? (
        <span>{error}</span>
      ) : (
        <span className={styles.signed}>Документы успешно подписаны</span>
      )}
    </>
  );
};
