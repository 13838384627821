import { Select } from "../../../../components/Select/ui/Select";
import { useEffect, useState } from "react";
import styles from "./ApplicationsStatusFilter.module.scss";
import { useQuery } from "@apollo/client";
import { GET_ALL_STATUSES } from "../../../../services/queries/queries/applications/allStatuses";

/** 
  Компонент для фильтрации списка заявок по статусу
  
  Пропсы:
  @param {function} setStatus - функция для изменения статуса
  @param {string} status - текущий выбранный статус заявки
  @param {function} setCurrentPageFilter - функция для управления пагинацией на странице с отфильтрованными данными
  @param {function} setCurrentPage - функция для управления пагинацией на странице с неотфильтрованными данными
  @returns {ReactElement} - Возвращает компонент Select
 
*/

export const ApplicationsStatusFilter = ({
  setStatus,
  status,
  setCurrentPageFilter,
  setCurrentPage,
}) => {
  const { data: allStatuses } = useQuery(GET_ALL_STATUSES);
  const currentStatus = sessionStorage.getItem("currentStatus");
  const [options, setOptions] = useState([
    { title: "Статус заявки", value: "" },
  ]);
  useEffect(() => {
    if (allStatuses && allStatuses?.applicationStatuses) {
      allStatuses?.applicationStatuses.forEach((item) => {
        setOptions((prev) => [
          ...prev,
          { value: item.value, title: item.title },
        ]);
      });
    }
  }, [allStatuses]);

  useEffect(() => {
    if (status) {
      setCurrentPageFilter(0);
      setCurrentPage(0);
      sessionStorage.setItem("currentStatus", status);
    } else {
      sessionStorage.removeItem("currentStatus");
    }
  }, [status]);

  useEffect(() => {
    if (currentStatus) {
      setStatus(currentStatus);
    }
  }, []);

  return (
    <Select
      variant="bordered-green"
      size="short"
      value={status}
      setValue={setStatus}
    >
      {options.map((item, index) => (
        <option key={index} value={item.value}>
          {item.title}
        </option>
      ))}
    </Select>
  );
};
