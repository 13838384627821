import { useState } from "react";
import { Button } from "../../Button";
import { AccountsItem } from "./AccountsItem/AccountsItem";
import styles from "./UserAccounts.module.scss";
import { useMutation } from "@apollo/client";
import { ADD_USER_ACCOUNT } from "../../../services/mutations/addUserAccount";
import { ALL_USERS } from "../../../services/queries/queries/user";
import { toast } from "react-toastify";

/**
Компонент для отображения аккаунтов пользователя

Пропсы:
@param {array} accounts - массив с информацией об аккаунтах пользователя
@param {string} uuid - уид пользователя
@returns {ReactElement} - возвращает область со списком аккаунтов пользователя

*/

export const UserAccounts = ({ accounts, uuid }) => {
  const [showFields, setShowFields] = useState(false);
  const [tokenValue, setTokenValue] = useState("");
  const [role, setRole] = useState("");
  const [permition, setPermition] = useState("");

  const [addAccount] = useMutation(ADD_USER_ACCOUNT, {
    refetchQueries: [ALL_USERS],
    fetchPolicy: "no-cache",
    onError: (error) => toast.error(error.message)
  });

  const addUserAccount = () => {
    const userInput = {
      apiKey: tokenValue,
      role: role === "Администратор" ? "AD" : "MA",
      permissionStatus: permition === "Разрешена" ? true : false,
    };

    addAccount({
      variables: {
        personUuid: uuid,
        userInput,
      },
    })
      .then(() => setShowFields(false))
      .catch((error) => toast.error(error.message));
  };

  return (
    <div className={styles.accountsWrapper}>
      <h3>Аккаунты пользователя</h3>
      <div className={styles.accountsContent}>
        <AccountsItem
          accounts={accounts}
          showFields={showFields}
          setShowFields={setShowFields}
          tokenValue={tokenValue}
          setTokenValue={setTokenValue}
          setRole={setRole}
          setPermition={setPermition}
          uuid={uuid}
          addUserAccount={addUserAccount}
        />
        <div className={styles.addAccountButtons}>
          {!showFields && (
            <Button
              type="button"
              variant="outline-green"
              className={styles.addAccount}
              onClick={() => setShowFields(true)}
            >
              Добавить аккаунт
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};
