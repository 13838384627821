import { useContext, useEffect, useMemo, useState } from "react";
import { BasesList } from "../../../components/BasesList";
import { TabsComponent } from "../../../components/Tabs";
import styles from "./Bases.module.scss";
import { TabPanel } from "react-tabs";
import { Context } from "../../../App";
import { ModalWindow } from "../../../components/ModalWindow/ui/ModalWindow";
import { CreatePhycicalForm } from "../../../components/Forms/ui/CreatePhycicalForm/CreatePhycicalForm";
import {
  CreateCompanyForm,
  CreateCompanyRepresentativeForm,
} from "../../../components/Forms";
import { useLazyQuery } from "@apollo/client";
import { PHYSICALPERSONSLIST } from "../../../services/queries/queries/physicalPersonsList";
import { Preloader } from "../../../components/Preloader";
import { ALL_COMPANIES } from "../../../services/queries/queries/companies/allCompaniesList";
import { ALL_COMPANY_REPRESENTATIVES_BY_PERSON_LIST } from "../../../services/queries/queries/representativesByPhysical/AllRepresentativesList";
import { ALL_REPRESENTATIVES_BY_COMPANY } from "../../../services/queries/queries/representativesByCompany/allRepresentativesByCompany";
import { FilteredSearch } from "../../../components/FilteredSearch";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";

const basesTabs = ["Физлица", "Юрлица", "ЮЛ - агент ФЛ", "ЮЛ - агент ЮЛ"];

export const Bases = () => {
  const { tab, tabIndex, showModal, setShowModal } = useContext(Context);
  const [currentPage, setCurrentPage] = useState(0);
  const [first, setFirst] = useState(10);
  const [offset, setOffset] = useState(currentPage * first);
  const [query, setQuery] = useState("");

  const [allPhysicalPersons, { data, loading, error }] = useLazyQuery(
    PHYSICALPERSONSLIST,
    { variables: { offset: offset, first: first }, fetchPolicy: "no-cache" }
  );

  const [
    allCompanies,
    { data: companyData, loading: companyLoading, error: companyError },
  ] = useLazyQuery(ALL_COMPANIES, {
    variables: { offset: offset, first: first },
    fetchPolicy: "no-cache",
  });

  const [
    allCompanyRepresentatives,
    {
      data: companyRepresentativesData,
      loading: companyRepresentativesLoading,
      error: companyRepresentativesError,
    },
  ] = useLazyQuery(ALL_COMPANY_REPRESENTATIVES_BY_PERSON_LIST, {
    variables: { offset: offset, first: first },
    fetchPolicy: "no-cache",
  });

  const [
    allCompanyesRepresentativesCompanies,
    {
      data: companyRepresentativesCompanyData,
      loading: companyRepresentativesCompanyLoading,
      error: companyRepresentativesCompanyError,
    },
  ] = useLazyQuery(ALL_REPRESENTATIVES_BY_COMPANY, {
    variables: { offset: offset, first: first },
    fetchPolicy: "no-cache",
  });

  const [physicalPersons, setPhysicalPersons] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [companyRepresentative, setCompanyRepresentative] = useState([]);
  const [companyRepresentativesCompany, setCompanyRepresentativeCompany] =
    useState([]);

  const totalPersons = data ? data.allPhysicalPersons?.totalCount : 0;
  const totalCompany = companyData
    ? companyData.allLegalPersons?.totalCount
    : 0;
  const totalCompanybyPhisical = companyRepresentativesData
    ? companyRepresentativesData.allLegalPersonRepresentedByPhysicalPersons
        ?.totalCount
    : 0;
  const totalAllPersons = companyRepresentativesCompanyData
    ? companyRepresentativesCompanyData.allLegalPersonRepresentedByLegalPerson
        ?.totalCount
    : 0;
  const totalPersonPages = Math.ceil(totalPersons / first);
  const totalCompanyPages = Math.ceil(totalCompany / first);
  const totalCompanybyPhisicalPages = Math.ceil(totalCompanybyPhisical / first);
  const totalAllPersonPages = Math.ceil(totalAllPersons / first);

  useEffect(() => {
    setOffset(currentPage * first);
  }, [currentPage, first]);

  const modalWindowVariantRender = useMemo(() => {
    setCurrentPage(0);
    setFirst(10);
    if (tab === "Новое физлицо" && showModal) {
      return (
        <ModalWindow show={showModal} setShowModal={setShowModal} size="xl">
          <CreatePhycicalForm modalTitle={"Физлицо"} />
        </ModalWindow>
      );
    }

    if (tab === "Новое юрлицо" && tabIndex === 3 && showModal) {
      return (
        <ModalWindow show={showModal} setShowModal={setShowModal} size="xl">
          <CreateCompanyRepresentativeForm
            isAgentForm={true}
            setShowModal={setShowModal}
          />
        </ModalWindow>
      );
    }

    if (tab === "Новое юрлицо" && showModal) {
      return (
        <ModalWindow
          show={showModal}
          setShowModal={setShowModal}
          size="xl"
          className={styles.companyModal}
        >
          <CreateCompanyForm setShowModal={setShowModal} />
        </ModalWindow>
      );
    }

    if (tab === "Новый представитель" && showModal) {
      return (
        <ModalWindow
          show={showModal}
          setShowModal={setShowModal}
          size="xl"
          className={styles.companyModal}
        >
          <CreateCompanyRepresentativeForm setShowModal={setShowModal} />
        </ModalWindow>
      );
    }
  }, [tab, showModal, setShowModal, tabIndex]);

  useEffect(() => {
    setCurrentPage(0);
    setFirst(10);
    setQuery("");
    if (tabIndex === 0) {
      allPhysicalPersons();
    }

    if (tabIndex === 1) {
      allCompanies();
    }

    if (tabIndex === 2) {
      allCompanyRepresentatives();
    }

    if (tabIndex === 3) {
      allCompanyesRepresentativesCompanies();
    }
  }, [
    allPhysicalPersons,
    allCompanyRepresentatives,
    allCompanyesRepresentativesCompanies,
    allCompanies,
    tab,
    tabIndex,
  ]);
  useEffect(() => {
    if (data && data.allPhysicalPersons) {
      setPhysicalPersons(data.allPhysicalPersons.edges);
      if (data.allPhysicalPersons.edges.length <= 0 && tabIndex === 0) {
        toast.error("Физлица не найдены");
      }
    }

    if (companyData && companyData.allLegalPersons) {
      setCompanies(companyData.allLegalPersons.edges);
      if (companyData.allLegalPersons.edges.length <= 0 && tabIndex === 1) {
        toast.error("Юрлица не найдены");
      }
    }

    if (
      companyRepresentativesData &&
      companyRepresentativesData.allLegalPersonRepresentedByPhysicalPersons
    ) {
      setCompanyRepresentative(
        companyRepresentativesData.allLegalPersonRepresentedByPhysicalPersons
          .edges
      );
      if (
        companyRepresentativesData.allLegalPersonRepresentedByPhysicalPersons
          .edges.length <= 0 &&
        tabIndex === 2
      ) {
        toast.error("Представители не найдены");
      }
    }

    if (
      companyRepresentativesCompanyData &&
      companyRepresentativesCompanyData.allLegalPersonRepresentedByLegalPerson
    ) {
      setCompanyRepresentativeCompany(
        companyRepresentativesCompanyData.allLegalPersonRepresentedByLegalPerson
          .edges
      );
      if (
        companyRepresentativesCompanyData.allLegalPersonRepresentedByLegalPerson
          .edges.length <= 0 &&
        tabIndex === 3
      ) {
        toast.error("Представители не найдены");
      }
    }
  }, [
    data,
    companyData,
    companyRepresentativesData,
    companyRepresentativesCompanyData,
    // tabIndex,
  ]);
  return (
    <>
      <div className={styles.basesInner}>
        <TabsComponent data={basesTabs}>
          <TabPanel>
            {loading ? (
              <Preloader />
            ) : (
              <BasesList
                personData={physicalPersons}
                page={currentPage}
                setPage={setCurrentPage}
                totalPersonPages={totalPersonPages || []}
              />
            )}
          </TabPanel>
          <TabPanel>
            {companyLoading ? (
              <Preloader />
            ) : (
              <BasesList
                companiesData={companies}
                page={currentPage}
                setPage={setCurrentPage}
                totalCompanyPages={totalCompanyPages || []}
              />
            )}
          </TabPanel>
          <TabPanel>
            {companyRepresentativesLoading ? (
              <Preloader />
            ) : (
              <BasesList
                representativesCompaniesData={companyRepresentative}
                page={currentPage}
                setPage={setCurrentPage}
                totalCompanybyPhisicalPages={totalCompanybyPhisicalPages || []}
              />
            )}
          </TabPanel>
          <TabPanel>
            {companyRepresentativesCompanyLoading ? (
              <Preloader />
            ) : (
              <BasesList
                agentsData={companyRepresentativesCompany}
                setShowModal={setShowModal}
                page={currentPage}
                setPage={setCurrentPage}
                totalAllPersonPages={totalAllPersonPages || []}
              />
            )}
          </TabPanel>
        </TabsComponent>
        <FilteredSearch
          response={
            tabIndex === 0
              ? allPhysicalPersons
              : tabIndex === 1
              ? allCompanies
              : tabIndex === 2
              ? allCompanyRepresentatives
              : tabIndex === 3
              ? allCompanyesRepresentativesCompanies
              : null
          }
          className={styles.basesSearch}
          setCurrentPage={setCurrentPage}
          setFirst={setFirst}
          query={query}
          setQuery={setQuery}
          placeholder={
            tabIndex === 0
              ? "ФИО, телефон, ИНН, СНИЛС"
              : tabIndex === 1
              ? "Название юрлица, ИНН"
              : tabIndex === 2
              ? "ФИО, телефон, ИНН, СНИЛС"
              : tabIndex === 3
              ? "Представл ЮЛ (название ЮЛ, ИНН)"
              : "Поиск"
          }
        />
      </div>
      {modalWindowVariantRender}
    </>
  );
};
