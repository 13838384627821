import { useEffect, useRef, useState } from "react";
import Calendar from "react-calendar";
import styles from "./FormCalendar.module.scss";
import { CalendarIcon } from "../../../assets/icons/CalendarIcon";
import { Input } from "../../Input";
import cn from "classnames";

/**
Календарь, предназначенный для вывода внутри форм.

Пропсы:

@param {object} register - объект, для регистрации поля в react-hook-form, значение для передачи можно взять из хука useForm(), который предоставляет библиотека,

@param {string} label - название календаря

@param {string} className - передача дополнительных стилей из компонента где вызывается календарь (строка)

@param {string} calendarBoxClassname - передача дополнительных стилей для дива, который оборачивает календарь (calendarBox) из компонента где вызывается календарь (строка)

@param {string} name - имя под которым поле регистрируется в react-hook-form (строка)

@param {number} width - ширина календаря

@param {function} setValue - функция для управления полем, в которое вводятся даты

@param {string} error - перменная для вывода кастомной ошибки, например связаной с возрастом

@param {string} variant - выбор определенной стилизации для поля календаря (строка)

@param {function} setError - функция, которая позволяет положить кастомную ошибку в error или удалить ее оттуда

@param {string} event - описать тому, кто создавал

@param {object} registerObj - объект для установки правил валидации поля календаря (например обязательное поле) Пример: registerObj={{ required: "Поле обязательно для заполнения" }}

@param {object} errors - объект с ошибками, предоставляемый библиоткекой react-hook-form (можно взять там же где и register). Если объект содержит ключ, совпадающий по названию с name, то для инпута с этим name выводится ошибка

@returns {ReactElement} - Возвращает инпут и компонент календаря
*/

export const FormCalendar = ({
  register,
  label,
  className,
  calendarBoxClassname,
  name,
  width,
  setValue,
  error,
  variant='bordered-green',
  setError,
  event,
  registerObj,
  errors,
  classNameInput,
}) => {
  const [value, onChange] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [isVisible, setIsVisible] = useState(false);
  const calendarRef = useRef(null);

  useEffect(() => {
    if (value) {
      const formattedDate = value.toLocaleDateString("ru-RU", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      });

      const currentYear = new Date().getFullYear();
      const age = currentYear - Number(formattedDate.split(".")[2]);

      if (event !== "issue") {
        if (age >= 18 && age <= 120) {
          setInputValue(formattedDate);
          setValue(name, formattedDate);
          if(setError){
            setError("");
          }
        } else {
          setInputValue(formattedDate);
          setValue(name, formattedDate);
          if(setError){
            setError("Вам должно быть от 18 до 120 лет");
          }
        }
      } else {
        setInputValue(formattedDate);
        setValue(name, formattedDate);
      }
    }
  }, [value, setError]); // определение возраста пользователя

  /**
  Функция handleInputChange удаляет все нецифровые символы из value и создает формат вывода даты для поля календаря

  @param {Event} e - ивент
  
  */

  const handleInputChange = (e) => {
    let value = e.target.value.replace(/[^\d]/g, "");
    if (value.length > 2 && value.length <= 4) {
      value = value.slice(0, 2) + "." + value.slice(2);
    }
    if (value.length > 5) {
      value =
        value.slice(0, 2) + "." + value.slice(2, 4) + "." + value.slice(4, 8);
    }
    e.target.value = value;
    setInputValue(value);
  };

  /**
  Функция  handleInputBlur  обрабатывает событие потери фокуса (blur) для поля ввода, проверяет, является ли введенное значение корректной датой в формате "дд.мм.гггг", и, если это так, вызывает функцию  onChange  с созданным объектом даты.
  
  */

  const handleInputBlur = () => {
    let parts = inputValue.split(".");
    if (parts.length === 3) {
      let [day, month, year] = parts.map(Number);
      let date = new Date(year, month - 1, day);
      if (!isNaN(date.getTime())) {
        onChange(date);
      }
    }
  };

  /**
    Функция  handleCalendarChange  обрабатывает выбор даты, обновляет состояние с выбранной датой и скрывает компонент выбора даты.

    @param {Date} date - дата
  */

  const handleCalendarChange = (date) => {
    onChange(date);
    setInputValue(
      `${String(date.getDate()).padStart(2, "0")}.${String(
        date.getMonth() + 1
      ).padStart(2, "0")}.${date.getFullYear()}`
    );
    setIsVisible(false);
  }; 

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (
        isVisible &&
        calendarRef.current &&
        !calendarRef.current.contains(e.target)
      ) {
        setIsVisible(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isVisible]); //скрытие календаря при клике вне его

  return (
    <div className={cn(styles.calendarBox, calendarBoxClassname)} ref={calendarRef}>
      <Input
        isFormField={true}
        label={label}
        name={name}
        register={register}
        registerObj={registerObj}
        errors={errors}
        size="full"
        variant={variant}
        value={inputValue}
        style={{ width: `${width}px` }}
        onChange={handleInputChange}
        onBlur={handleInputBlur}
        onClick={() => setIsVisible(!isVisible)}
        placeholder="ДД.ММ.ГГГГ"
        className={cn({
          [styles.calendarField]: error && error !== "",
        }, classNameInput)}
      />
      <CalendarIcon onClick={() => setIsVisible(!isVisible)} />
      {isVisible && (
        <Calendar
          onChange={handleCalendarChange}
          className={cn(styles.formCalendar, className)}
          value={value}
          locale="ru-RU"
          onClickDay={() => setIsVisible(false)}
        />
      )}
      {error !== "" ? <p className={styles.error}>{error}</p> : null}
    </div>
  );
};
