import { formattedDateFunc } from "../../../../helpers/formattedDateFunc";
import { CreateAppealForm } from "../../../Forms";
import { InfoList } from "../../../InfoList/ui/InfoList";
import { ModalWindow } from "../../../ModalWindow";
import { Pagination } from "../../../Pagination/ui/Pagination";
import { StatusLabel } from "../../../StatusLabel/ui/StatusLabel";
import styles from "./AppealsList.module.scss";
import { ReactComponent as Notification } from "../../../../assets/svgs/notfication.svg";
import { useState, useEffect, Fragment } from "react";
import { useDispatch } from "react-redux";
import { notificationActions } from "../../../../redux/slices/notificationsSlice";

/**
Компонент для вывода списка обращений

Пропсы:

@param {array} appealsData - массив с информацией об обращениях

@returns {ReactElement} - возвращает списко обращений
*/

export const AppealsList = ({ appealsData }) => {
  const [page, setPage] = useState(1);
  const totalResults = 10;
  const indexOfLastPage = page * totalResults;
  const indexOfFirstPage = indexOfLastPage - totalResults;
  const [activeAppeal, setActiveAppeal] = useState(null);
  const [updatedAppealsData, setUpdatedAppealsData] = useState([]);
  const [readNotifications, setReadNotifications] = useState({});
  const [superUser, setSuperUser] = useState(false)
  const dispatch = useDispatch();

  const handleInfoListClick = (appealId) => {
    setActiveAppeal(appealId);
    localStorage.setItem('read', true)
    setReadNotifications((prev) => ({
      ...prev,
      [appealId]: true,
    }));
  };

  useEffect(() => {
    if (appealsData) {
      setUpdatedAppealsData(appealsData);
    }
  }, [appealsData]);

  useEffect(() => {
    const socketLink = process.env.REACT_APP_API_BASE_URL_WS;
    const token = localStorage.getItem("accessToken");
    const fullSocketLink = socketLink + `?token=${token}`;
    const socket = new WebSocket(fullSocketLink);

    socket.onmessage = (event) => {
      const data = JSON.parse(event.data);
      if (data) {
        setUpdatedAppealsData((prevData) => {
          return prevData.map((appeal) => {
            if (appeal.node.uuid === data.ticket_id) {
              return {
                ...appeal,
                node: {
                  ...appeal.node,
                  status:
                    data.status !== undefined
                      ? data.status
                      : appeal.node.status,
                  isRead: data?.isRead,
                },

              };
            }
            return appeal;
          });
        });
      }
    };

    socket.onclose = () => {
      console.log("WebSocket connection closed");
    };

    socket.onerror = (error) => {
      console.error("WebSocket error:", error);
    };

    return () => {
      socket.close();
    };
  }, []);

  /** 
    Функция appealUserName выодит автора последнего сообщения в обращении.
    @param {object} appeal - объект, содержащий информации о роли пользователя и его элетронную почту. Если роль пользователя Service (SE), автором сообщения выводится поддержка, если другая роль, то емэйл пользователя
  */

  const appealUserName = (appeal) => {
    const userEmail = appeal?.user?.profile?.email;
    const superUser = appeal?.user?.isSuperuser;

    if (superUser || !userEmail) {
      return "Поддержка";
    } else {
      return userEmail;
    }
  };

  useEffect(() => {
    if (updatedAppealsData.length > 0) {
      dispatch(notificationActions.changeReads(updatedAppealsData));
      updatedAppealsData.find(item => {
        setSuperUser(item?.node?.messages[0]?.user?.isSuperuser)
      })
    }
  }, [updatedAppealsData]);

  return (
    <ul className={styles.appealsList}>
      {updatedAppealsData.length > 0 ? (
        updatedAppealsData
          .slice(indexOfFirstPage, indexOfLastPage)
          .map((appeal) => (
            <Fragment key={appeal.node.id}>
              <InfoList onClick={() => handleInfoListClick(appeal.node.id)}>
                <div className={styles.appealsInner}>
                  <div className={styles.appealText}>
                    <span className={styles.appealTitle}>Обращение</span>
                    <span>
                      {appeal.node.description.length > 50
                        ? appeal.node.description.slice(0, 50) + "..."
                        : appeal.node.description}
                    </span>
                  </div>
                  <div className={styles.appealInfo}>
                    <span className={styles.appealTitle}>
                      Последнее сообщение от
                    </span>
                    <span>
                      {appeal.node.messages.length > 0
                        ? appealUserName(appeal.node.messages[0]) +
                          " в " +
                          formattedDateFunc(
                            appeal.node.messages[0].updatedAt,
                            true
                          )
                        : "-"}
                    </span>
                  </div>
                  <div className={styles.appealStatus}>
                    {appeal.node.status === "OPEN" ? (
                      <StatusLabel text="В работе" variant="positive" />
                    ) : (
                      <StatusLabel text={"Завершено"} variant="neutral" />
                    )}
                  </div>
                  <div className={styles.notificationBlock}>
                    {!appeal?.node?.isRead && superUser ? <Notification /> : null}
                  </div>
                </div>
              </InfoList>
              {activeAppeal === appeal.node.id && (
                <ModalWindow
                  show={activeAppeal}
                  setShowModal={setActiveAppeal}
                  size="xl"
                >
                  <CreateAppealForm
                    id={activeAppeal}
                    uuid={appeal.node.uuid}
                    hideModal={setActiveAppeal}
                    ticketId={appeal.node.id}
                  />
                </ModalWindow>
              )}
            </Fragment>
          ))
      ) : (
        <p className={styles.paginationInfo}>Больше записей нет</p>
      )}
    </ul>
  );
};
