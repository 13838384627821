/**
Функция позволяет вернуть дату в определенном формате, например 10 октября 2023 14:30. Если isShortFormat = true, то выведется только время, например 14:30

@param {string} dateArg - дата
@param {boolean} isShortFormat - флаг для показа полной или сокращенной даты
@returns {Date} - возвращает дату в определенном формате
*/

export const formattedDateFunc = (dateArg, isShortFormat) => {
  const date = new Date(dateArg);

  const options = {
    day: "2-digit",
    month: "long",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  };

  const formattedDate = date.toLocaleDateString("ru-RU", options);
  const formattedTime = date.toLocaleTimeString("ru-RU", {
    hour: "2-digit",
    minute: "2-digit",
  });

  if (isShortFormat) {
    const finalFormattedDate = `${formattedTime}`;
    return finalFormattedDate;
  } else {
    const finalFormattedDate = `${
      formattedDate.split(" г.")[0]
    } ${formattedTime}`;

    return finalFormattedDate;
  }
};
