/**
Функция для проверки нажатой клавиши на соответствие регулярному выражению

@param {KeyboardEvent} e - лбъект эвент для отслеживания нажатой клавиши
@param {RegExp} - регулярное выражение для проверки на соответствие

*/

export const checkValidation = (e, reg) => {
  if (!reg.test(e.key)) {
    e.preventDefault();
  }
}