import { useForm } from "react-hook-form";
import styles from "./CreateClientDocumentsForm.module.scss";
import { Input } from "../../../Input";
import { Select } from "../../../Select/ui/Select";
import { RadioButton } from "../../../RadioButton";
import { FormCalendar } from "../../../FormCalendar";
import { useState, useEffect } from "react";
import { checkValidation } from "../../../../helpers/checkValidation";
import { Button } from "../../../Button";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { transformDate } from "../../../../helpers/transformDate";
import { cleanObject } from "../../../../helpers/cleanObjects";
import { CLIENT_INFO } from "../../../../services/queries/queries/client/clientInfo";
import { UPDATE_PHYSICAL_PERSON } from "../../../../services/queries/queries/updatePerson";
import { unTransformDate } from "../../../../helpers/untransformDate";
import { GET_ADDRESSES } from "../../../../services/queries/queries/search";
import { useNavigate } from "react-router-dom";
import useInputSearch from "../../../../hooks/useInputSearch";
import { SearchAddressInput } from "../SearchAddressInput/SearchAddressInput";

/**
Компонент формы для личного кабинета клиента, в которой клиент может указать личные данные и отправить их на сервер

Пропсы:
@param {object} clientData - объект с информацией о клиенте
@returns {ReactElement} возвращает форму с полями для заполнения личных данных клиента
*/

export const CreateClientDocumentsForm = ({ clientData }) => {
  const id = localStorage.getItem("id");

  const { data } = useQuery(CLIENT_INFO, {
    variables: { id },
    skip: !id,
    fetchPolicy: "no-cache",
  }); //запрос данных о клиенте при повторном открытии формы, запрос уйдет если передан id клиента

  const [ageError, setAgeError] = useState("");
  const navigate = useNavigate();
  const {
    search,
    setSearch,
    data: searchData,
  } = useInputSearch("", "GET_ADDRESSES");
  const [otherToAddress, setOtherToAddress] = useState({});

  const formatAddress = [
    otherToAddress?.index || "",
    otherToAddress?.region || "",
    otherToAddress?.district || "",
    otherToAddress?.locality || otherToAddress?.locality !== "None None"
      ? otherToAddress?.locality
      : "",
    otherToAddress?.locationName || "",
    otherToAddress?.settlementWithType || "",
    // otherToAddress?.value || "",
    otherToAddress?.street || "",
    otherToAddress?.house ? "дом " + otherToAddress?.house : "",
    otherToAddress?.building && !otherToAddress?.apartment ? "кв " + otherToAddress?.building : "",
    otherToAddress?.apartment && !otherToAddress?.building ? "кв " + otherToAddress?.apartment : ''
  ]
    .filter(Boolean)
    .join(", ");

  const [addressError, setAddressError] = useState(false);

  const innValidation = /^[0-9]+$/; //выражение для валидации ИНН

  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    reset,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      citizenship: "РФ",
    },
  });

  const snilsValidation = /^[0-9\-]+$/; // выражение для валидации СНИЛС

  const [updateClient] = useMutation(UPDATE_PHYSICAL_PERSON); //функция для обновления данных клиента при их изменении

  const onSubmitHandler = (formData) => {
    const physycalData = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      gender:
        formData.gender === "Мужской"
          ? "MALE"
          : formData.gender === "Женский"
          ? "FEMALE"
          : null,
      patronymic: formData.patronimic,
      citizenship: formData.citizenship || 'РФ',
      dateOfBirth: transformDate(formData.dateOfBirth),
      placeOfBirth: formData.placeOfBirth,
      inn: formData.inn,
      snils: formData.snils ? formData.snils.split("-").join("") : "",
    };

    const documentsData = {
      code: formData.departmentCode
        ? formData.departmentCode.split("-").join("")
        : "",
      dateIssue: transformDate(formData.issueDate),
      issuedBy: formData.whomIssued,
      number: formData.number,
      series: formData.series,
      type:
        formData.documents.value !== "Загранпаспорт"
          ? "PASSPORT"
          : "FOREIGN_PASSPORT",
    };

    const addressData = {
      region:
        otherToAddress?.region || search?.region || formatAddress?.region || "",
      regionType: "",
      district: otherToAddress?.district || search?.district || searchData?.district,
      locality:
        otherToAddress?.locality ||
        search?.locality ||
        formatAddress?.locality ||
        null,
      house: otherToAddress?.house || search?.house,
      building: otherToAddress?.building || search?.flat,
      structure: otherToAddress?.structure || search?.structure,
      okato: search?.okato || otherToAddress?.okato || searchData?.okato,
      oktmo: search?.oktmo || otherToAddress?.oktmo || searchData?.oktmo,
      index: otherToAddress?.index || search?.index,
      city: otherToAddress?.city || search?.city,
      street: otherToAddress?.street || search?.street,
      streetType: "",
      country: otherToAddress?.country || search?.country,
      value: otherToAddress?.value,
      block: otherToAddress?.block || search?.block,
      regionKladrId: otherToAddress?.regionKladrId || search?.regionKladrId,
      fias: otherToAddress?.fias,
      kladr: otherToAddress?.kladr,
      apartment: searchData?.apartment || otherToAddress?.apartment || search?.apartment
    };

    const userData = {
      email: clientData?.physicalPersonProfile?.profile?.email,
      phone: clientData?.physicalPersonProfile?.profile?.phone,
    };

    updateClient({
      variables: {
        inputPhysicalPerson: cleanObject(physycalData),
        inputDocument: cleanObject(documentsData),
        inputUser: cleanObject(userData),
        inputAddress: addressData,
      },
      skip: !clientData?.physicalPersonProfile?.uuid,
    }).then(() => {
      navigate("/client-cabinet/");
      localStorage.setItem("fullData", true);
    });
  };

  useEffect(() => {
    if (clientData) {
      reset({
        firstName: clientData?.physicalPersonProfile?.profile?.firstName,
        lastName: clientData?.physicalPersonProfile?.profile?.lastName,
        patronimic: clientData?.physicalPersonProfile?.profile?.patronymic,
        gender:
          clientData?.physicalPersonProfile?.profile?.gender !== "FEMALE"
            ? "Мужской"
            : "Женский",
        dateOfBirth: unTransformDate(
          clientData?.physicalPersonProfile?.profile?.dateOfBirth
        ),
        placeOfBirth: clientData?.physicalPersonProfile?.profile?.placeOfBirth,
        inn: clientData?.physicalPersonProfile?.profile?.inn,
        snils: clientData?.physicalPersonProfile?.profile?.snils,
        documents:
          clientData?.physicalPersonProfile?.profile?.document?.type !==
          "FOREIGN_PASSPORT"
            ? "Паспорт гражданина РФ"
            : "Загранпаспорт",
        series: clientData?.physicalPersonProfile?.profile?.document?.series,
        number: clientData?.physicalPersonProfile?.profile?.document?.number,
        issueDate: unTransformDate(
          clientData?.physicalPersonProfile?.profile?.document?.dateIssue
        ),
        departmentCode: clientData?.physicalPersonProfile?.profile?.document?.code,
        whomIssued: clientData?.physicalPersonProfile?.profile?.document?.issuedBy,
      });

    }
  }, [clientData, reset]);

  return (
    <form
      onSubmit={handleSubmit(onSubmitHandler)}
      className={styles.documentsForm}
    >
      <h3 className={styles.formTitle}>Персональные данные</h3>
      <div className={styles.info}>
        <Input
          isFormField={true}
          name="lastName"
          label="Фамилия"
          variant="white-bg"
          register={register}
          registerObj={{ required: "Поле обязательно для заполнения" }}
          size="full"
          errors={errors}
          errorClassName={styles.documentsError}
        />
        <Input
          isFormField={true}
          name="firstName"
          label="Имя"
          variant="white-bg"
          register={register}
          registerObj={{ required: "Поле обязательно для заполнения" }}
          size="full"
          errors={errors}
          errorClassName={styles.documentsError}
        />
        <Input
          isFormField={true}
          name="patronimic"
          label="Отчество"
          variant="white-bg"
          register={register}
          size="full"
          errors={errors}
        />
        <label className={styles.radiosBox}>
          Пол
          <RadioButton
            name="gender"
            text1="Мужской"
            text2="Женский"
            register={register}
            registerObj={{ required: "Поле обязательно для заполнения" }}
            variant="green"
            errors={errors}
            errorClassName={styles.documentsError}
          />
        </label>
        <FormCalendar
          isFormField={true}
          label="Дата рождения"
          name="dateOfBirth"
          register={register}
          registerObj={{ required: "Поле обязательно для заполнения" }}
          setValue={setValue}
          calendarBoxClassname={styles.calendarBox}
          className={styles.calendar}
          error={ageError}
          setError={setAgeError}
          variant="white-bg"
          errors={errors}
          errorClassName={styles.documentsError}
        />
        <Input
          isFormField={true}
          name="placeOfBirth"
          label="Место рождения"
          variant="white-bg"
          size="full"
          register={register}
          registerObj={{ required: "Поле обязательно для заполнения" }}
          errors={errors}
          errorClassName={styles.documentsError}
        />

        <SearchAddressInput
          data={searchData}
          setSearch={setSearch}
          defaultValues={clientData?.physicalPersonProfile?.profile?.registrationAddress || {}}
          setOtherToAddress={setOtherToAddress}
          otherToAddress={otherToAddress}
          formatAddress={formatAddress}
          addressError={addressError}
          setAddressError={setAddressError}
          variant="white-bg"
          // showFullAddress={false}
          className={styles.addressBox}
          size=""
          baseAddress={clientData?.physicalPersonProfile?.profile?.registrationAddress?.value}
          fullAddressClassname={styles.fullAddress}
          btnClassName={styles.fullAddressBtn}
          
        />


        <Input
          isFormField={true}
          name="citizenship"
          label="Гражданство"
          variant="white-bg"
          size="full"
          register={register}
          registerObj={{ required: "Поле обязательно для заполнения" }}
          errors={errors}
          value="РФ"
          readOnly
        />
        <Input
          isFormField={true}
          name="inn"
          label="ИНН"
          variant="white-bg"
          size="full"
          register={register}
          mask="999999999999"
          placeholder="000000000000"
          registerObj={{
            maxLength: {
              value: 12,
              message: "Максимум 12 цифр",
            },
            required: "Поле обязательно для заполнения",
          }}
          onKeyPress={(e) => {
            checkValidation(e, innValidation);
          }}
          errors={errors}
          errorClassName={styles.documentsError}
        />
      </div>
      <h3 className={styles.formTitle}>Удостоверение личности и СНИЛС</h3>
      <div className={styles.documents}>
        <Select
          isFormSelect={true}
          label="Документ"
          options={["Паспорт гражданина РФ", "Загранпаспорт"]}
          variant="white-bg"
          size="full"
          name="documents"
          className={styles.documentSelect}
          register={register}
        />
        <label className={styles.series}>
          <>
            <Input
              isFormField={true}
              name="series"
              label="Серия и номер"
              variant="white-bg"
              register={register}
              registerObj={{ required: true }}
              mask="9999"
              onKeyPress={(e) => {
                checkValidation(e, innValidation);
              }}
              errors={errors}
              className={styles.seriesField}
              placeholder="0000"
            />
            <Input
              isFormField={true}
              name="number"
              variant="white-bg"
              size="full"
              register={register}
              registerObj={{ required: true }}
              mask="999999"
              errors={errors}
              className={styles.numberField}
              placeholder="000000"
            />
          </>
        </label>
        <FormCalendar
          label="Дата выдачи"
          name="issueDate"
          register={register}
          variant="white-bg"
          registerObj={{ required: "Поле обязательно для заполнения" }}
          setValue={setValue}
          calendarBoxClassname={styles.calendarBox}
          className={styles.calendar}
          errors={errors}
          errorClassName={styles.documentsError}
        />
        <Input
          isFormField={true}
          name="whomIssued"
          label="Кем выдан"
          variant="white-bg"
          size="full"
          register={register}
          registerObj={{ required: "Поле обязательно для заполнения" }}
          errors={errors}
          errorClassName={styles.documentsError}
        />
        <Input
          isFormField={true}
          name="departmentCode"
          label="Код подразделения"
          mask="999-999"
          variant="white-bg"
          size="full"
          register={register}
          errors={errors}
          placeholder="000000"
          registerObj={{ required: "Поле обязательно для заполнения" }}
          errorClassName={styles.documentsError}
        />
        <Input
          isFormField={true}
          name="snils"
          label="СНИЛС"
          variant="white-bg"
          mask="999-999-999-99"
          size="full"
          setValue={setValue}
          register={register}
          registerObj={{ required: "Поле обязательно для заполнения" }}
          placeholder="00000000000"
          onKeyPress={(e) => {
            checkValidation(e, snilsValidation);
          }}
          errors={errors}
          errorClassName={styles.documentsError}
        />
      </div>
      <Button type="submit" variant="green" className={styles.documentsBtn}>
        Сохранить
      </Button>
    </form>
  );
};
