import {useCallback, useState} from "react";
import {useDispatch} from "react-redux";
import {BaseFinderTemplate} from "../../../../../BaseFinderTemplate/BaseFinderTemplate";
import {Button} from "../../../../../Button";
import styles from "./EntityAgentBaseFinder.module.scss";
import {useQuery} from "@apollo/client";
import {
  ALL_REPRESENTATIVES_BY_COMPANY
} from "../../../../../../services/queries/queries/representativesByCompany/allRepresentativesByCompany";
import {setParticipant} from "../../../../../../redux/slices/application";
import { newRoolParticipantRole } from "../../../../../../constants/constants";

/**
  Компонент для поиска юрлица представителя юрлица

  Пропсы:
  @param {function} setShow - функция для управления видимостью компонента на странице
  @param {function} setData - функция для управления данными внутри data
  @param {function} setEntityData - функция для управления данными внутри entityData
  @param {object} cardId - объкт, содержащий информацию об участнике
  @param {function} setParticipantType - функция для изменения типа участника

*/


const ListRow = ({ data }) => {
  return (
    <li
      className={styles.line}
    >
      <div className={styles.column}>
        <strong>Агент </strong>
        <strong>{data?.legalPerson?.fullName}</strong>
        <strong>{data?.representative?.lastName} {data?.representative?.firstName} {data?.representative?.patronymic}</strong>
      </div>
      <div className={styles.column}>
        <strong>Юрлицо</strong>
        <strong>{data?.representedLegalPerson?.fullName}</strong>
      </div>
    </li>
  );
};



export const EntityAgentBaseFinder = ({setShow, setData, setEntityData, cardId, setParticipantType }) => {
  const [search, setSearch] = useState('')
  const dispatch = useDispatch();

  const {data} = useQuery(ALL_REPRESENTATIVES_BY_COMPANY, {
		variables: {
			search: search,
      first: 10,
      offset: 0
		},
	},);

	const array = data?.allLegalPersonRepresentedByLegalPerson?.edges

  const onChoose = useCallback((id) => {
    const entity = array?.find((item) => item?.node?.id === id);
    const participant = {
      ...entity,
      id: cardId?.id,
      participantType: "represented_legal_person",
      role: newRoolParticipantRole,
    };

    dispatch(setParticipant(participant));

    setData(participant);
    setEntityData(entity?.node?.representedLegalPerson)
    setParticipantType("represented_legal_person")
    setShow(false);
  }, [array, setShow]);

  return (
    <BaseFinderTemplate
      inputLabel="Поиск по базе ЮЛ - агентов ЮЛ"
      isEmpty={!search}
      emptyText="Начните вводить название организации, ИНН, КПП, ОГРН"
      setSearch={setSearch}
    >
      <div className={styles.full}>
          <ul className={styles.resultList}>
            {search && array?.map((item) => (
              <div className={styles.row}>
                <ListRow
                  key={item?.node?.id}
                  data={item?.node}
                  onChoose={onChoose}
                />
                <Button variant="green" type="button" onClick={() => {onChoose(item?.node?.id)}}>
                  Добавить
                </Button>
              </div>
            ))}
          </ul>
        </div>
    </BaseFinderTemplate>
  );
};
