import React, {
  useRef,
  useState,
  useCallback,
} from "react";
import styles from "./DeclarationBlock.module.scss";
import { CryptoToken } from "../../../../../CryptoToken";
import { TokenKey } from "../../../../../../assets/icons/TokenKey";
import { downloadSigFile } from "../../../../../../helpers/downloadSigFile";
import { downloadExistFiles } from "../../../../../../helpers/downloadExistFile";

/**
Компонент для работы с заявлением в заявке

Пропсы:

@param {object} declaration - объект с данными заявления
@param {string} applicationId - id заявки
@param {array} participants - массив с информацией об участниках заявки
@param {function} applicationInfo - запрос для обновления информации по заявке. Принимает applicationId

@returns {ReactElement} - DeclarationBlock
*/

export const DeclarationBlock = ({
  declaration,
  applicationId,
  participants,
  applicationInfo,
}) => {
  //declaration - все данные по заявлениям
  const accessToken = localStorage.getItem("accessToken"); // необходим для скачивания файла
  const toastRefs = useRef([]);
  const [checkPluginStatus, setCheckPluginStatus] = useState(false);

  const addToRefs = (el) => {
    if (el && !toastRefs.current.includes(el)) {
      toastRefs.current.push(el);
    }
  };


  const getParticipantId = useCallback(
    (item) => {
      const name =
        item?.person?.lastName +
        " " +
        item?.person?.firstName +
        " " +
        item?.person?.patronymic;

      const currentParticipant = participants?.find(
        (el) =>
          el?.physicalPerson?.lastName +
            " " +
            el?.physicalPerson?.firstName +
            " " +
            el?.physicalPerson?.patronymic ===
            name ||
          el?.representative?.lastName +
            " " +
            el?.representative?.firstName +
            " " +
            el?.representative?.patronymic ===
            name
      );
      const currentId = currentParticipant?.id;

      return currentId;
    },
    [participants]
  );

  const getParticipantInn = useCallback(
    (item) => {
      const name =
        item?.person?.lastName +
        " " +
        item?.person?.firstName +
        " " +
        item?.person?.patronymic;

      const currentParticipant = participants?.find(
        (el) =>
          (el.participantType === "LEGAL_PERSON" &&
            el?.physicalPerson?.lastName +
              " " +
              el?.physicalPerson?.firstName +
              " " +
              el?.physicalPerson?.patronymic ===
              name) ||
          (el.participantType === "LEGAL_PERSON" &&
            el?.representative?.lastName +
              " " +
              el?.representative?.firstName +
              " " +
              el?.representative?.patronymic ===
              name)
      );
      const currentInn =
        currentParticipant?.representative?.inn ||
        currentParticipant?.physicalPerson?.inn;

      return currentInn;
    },
    [participants]
  );

  return (
    <div className={styles.declarationContainer}>
      <div className={styles.declarationText}>{declaration?.title}</div>
      <div className={styles.rightBlock}>
        <div className={styles.oneLine}>
          <div
            className={styles.declarationRightText}
            onClick={() => downloadExistFiles(accessToken, declaration?.pdfUrl)}
          >
            Посмотреть
          </div>
          {declaration?.sigFile !== (null || "") && (
            <span
              className={styles.declarationRightText}
              onClick={() =>
                downloadSigFile(
                  declaration?.presignedSigUrl,
                  declaration?.file?.split(".")[0]?.split("/")[2] +
                    "." +
                    declaration?.file?.split(".")[1]
                )
              }
            >
              Скачать подпись
            </span>
          )}
        </div>
        {declaration?.documentsSignatures?.map((item, index) => (
          <div className={styles.centralBMenu} key={index}>
            <div className={styles.centralLeftText}>
              {item?.person?.lastName +
                " " +
                item?.person?.firstName +
                " " +
                item?.person?.patronymic}
            </div>
            <div className={styles.dashed}></div>
            <div className={styles.statementSignWrapper}>
              {item.state !== "COMPLETE" && (
                <abbr
                  ref={addToRefs}
                  title={
                    checkPluginStatus
                      ? "Подписать токеном"
                      : "Плагин КриптоПро не найден"
                  }
                  className={styles.tokenKey}
                >
                  <CryptoToken
                    showEdsBtn={false}
                    tokenBtnContent={
                      <TokenKey
                        className={!checkPluginStatus ? styles.negativeSvg : ""}
                      />
                    }
                    tokenBtnClassName={styles.tokenBtn}
                    file={declaration?.presignedUrl}
                    fileName={declaration?.title}
                    applicationId={applicationId}
                    applicationInfo={applicationInfo}
                    documentId={item?.id}
                    setCheckPluginStatus={setCheckPluginStatus}
                    inn={getParticipantInn(item)}
                    participantId={getParticipantId(item)}
                    participantFullName={
                      item?.person?.lastName +
                      " " +
                      item?.person?.firstName +
                      " " +
                      item?.person?.patronymic
                    }
                  />
                </abbr>
              )}

              {item?.state === "START" && (
                <div className={styles.centralRightText}>ждем подпись</div>
              )}
              {item?.state === "WAITING" && (
                <div className={styles.centralRightText}>ждем подпись</div>
              )}
              {item?.state === "WAITING_SEND" && (
                <div className={styles.centralRightText}>ждем подпись</div>
              )}
              {item?.state === "REJECTED" && (
                <div className={styles.centralRightRedText}>
                  техпакет не подписан
                </div>
              )}
              {item?.state === "ERROR" && (
                <div className={styles.centralRightRedText}>
                  ошибка подписания
                </div>
              )}
              {item?.state == "COMPLETE" && (
                <div className={styles.centralRightText}>
                  <span className={styles.greenRightText}>Подписано</span>{" "}
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
