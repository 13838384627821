import styles from './StatusLabel.module.scss'
import cn from 'classnames'

/**
Компонент для показа разноцветных статусов

Пропсы:
@param {string} text - текст, для отображения внутри статуса
@param {string} className - помогает передать дополнительные стили для списка из родительского компонента
@param {string} variant - выбор цвета статуса в зависимости от выбранного варианта
@returns {ReactElement} - возвращает цветной статус с выбранным цветом

*/

export const StatusLabel = ({text, className, variant}) => {
  return(
    <span className={cn(styles.statusLabel, className, {
      [styles.negative]: variant === 'negative',
      [styles.positive]: variant === 'positive',
      [styles.neutral]: variant === 'neutral',
      [styles.warning]: variant === 'warning'
    })}>{text}</span>
  )
}