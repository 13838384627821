import styles from './InfoList.module.scss'
import cn from 'classnames'

/**
Стилизованная оболочка для отображения элементов списков

Пропсы:

@param {ReactNode} children - содержимое компонента

@param {string} className - дополнительные стили, которые могут быть переданы из компонента, где вызывается InfoList

@returns {JSX.IntrinsicElements} - возвращает элемнт списка 

*/

export const InfoList = ({children, className, ...props}) => {
  return(
    <li className={cn(styles.infoList, className)} {...props}>{children}</li>
  )
}