/**
Функция translateStatus принимает статус и возвращает вместо него заранее указанную строку на русском языке, соответствующую заданому статусу

@param {string} status - статус, полученный с сервера
@param {string} kuvd - КУВД от росреестра (если есть)
@returns {string} возвращает строку, соответствующую переданному статусу или строку Неизвестный статус
*/


export const translateStatus = (status, kuvd) => {
  const statusTranslations = {
    SEND_TO: "Отправлено в РР",
    PENDING: "В ожидании",
    SEND_ERROR: "Ошибка при отправке",
    REJECTED: "Завершен отказом",
    ERROR: "Ошибка при обработке запроса",
    PROCESSED: "Обработка завершена",
    SEND_REQUEST: "Запрос отправлен",
    VALIDATIONERROR: "Проверка не пройдена",
    VALIDATIONE: "На проверке ФЛК",
    GENERATE_PACKAGE: "Пакет сформирован",
    ACCEPTED: `Принято от заявителя. Присовен ${kuvd}`,
    AWAITINGPAYMENTS: "Ожидание оплаты",
    SENTTOPROCCESED: "Отправлено в ПКУРП",
    QUITTANCESCREATED: "Сформирована квитанция",
    TIMEOUTED: "Истечение срока, предоставленного для оплаты",
    NOINFORMATION:
      "Сведения отсутствуют (используется только для предоставления сведений)",
    SUSPENDED: "Приостановлена",
    PAID: "Оплачено",
    RETURNED: "Возврат без рассмотрения",
    INTERRUPTED: "Прекращено по инициативе заявителя",
  };



  return statusTranslations[status] || "Неизвестный статус";
}