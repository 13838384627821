import { useLazyQuery, useMutation } from "@apollo/client";
import { Input } from "../../../Input";
import { DELETE_PHYSICAL_PERSON_DOCUMENT } from "../../../../services/queries/mutations/physicalPersons/deleteDocument";
import { BasketIcon } from "../../../../assets/icons/BasketIcon";
import styles from "./UploadField.module.scss";
import { useCallback, useEffect, useRef, useState } from "react";
import { Preloader } from "../../../Preloader";
import { GET_SIGS_FILES } from "../../../../services/queries/mutations/physicalPersons/getSigFiles";
import { SignatureStatus } from "../SignatureStatus/SignatureStatus";
import { Button } from "../../../Button";
import { CryptoToken } from "../../../CryptoToken/ui/CryptoToken";
import { FileField } from "../../../FileField";
import { useForm } from "react-hook-form";
import { UPLOAD_PHYSICAL_PERSON_DOCUMENT } from "../../../../services/queries/mutations/physicalPersons/uploadDocument";
import { PERSON_INFO } from "../../../../services/queries/queries/personInfo";
import { SIGNATURE_PHYSICAL_PERSON_DOCUMENT } from "../../../../services/queries/mutations/physicalPersons/signatureDocument";

/**

Компонент для загрузки документов из карточки физлица. Кнопка + добавляет пустое поле, при добавлении файла в пустое поле, происходит загрузка этого файла на сервер и появляется возможность для его подписания и скачивания самого файла. При подписании изменение статуса документа отслеживается через вебсокет и статус передается в компонент SignatureStatus, после успешного подписания документа появляется возможность скачать sig-файл

Пропсы:

@param {array} documentsData - массив с текущей информацией о загруженных документах пользователя

@param {string} id - id физлица

@param {string} uuid - uuid физлица

@param {function} downloadFile - функция, которая позволяет скачать файл, загруженный в поле

@param {boolean} personLoading - статус загрузки, который показывает прелоадер при срабатывании запроса на обновление массива documentsData

@param {object} signatureActive - информация о статусе электронной подписи физлица, если подписи нет, подписать документы через приложение нельзя

@returns {ReactElement} - возвращает область для загрузки и отображения уже загруженных документов и статуса подписания
*/

export const UploadField = ({
  documentsData,
  id,
  uuid,
  downloadFile,
  personLoading,
  signatureActive,
}) => {
  const [getSigFiles, { data: sigData }] = useLazyQuery(GET_SIGS_FILES, {
    fetchPolicy: "no-cache",
  });

  const [deleteDocument, { isLoading: deleteLoading }] = useMutation(
    DELETE_PHYSICAL_PERSON_DOCUMENT
  );
  const [uploadData, setUploadData] = useState([]);
  const [values, setValues] = useState({});
  const { register, getValues, setValue, watch } = useForm();

  const [physicalDocumentUpload, { data, loading: uploadLoading }] =
    useMutation(UPLOAD_PHYSICAL_PERSON_DOCUMENT, {
      refetchQueries: [{ query: PERSON_INFO, variables: { id } }],
    });

  const [
    signatureDocument,
    { data: signatureData, loading: signatureLoading },
  ] = useMutation(SIGNATURE_PHYSICAL_PERSON_DOCUMENT, {
    refetchQueries: [{ query: PERSON_INFO, variables: { id } }],
  });

  /**
  Функция removeDocument удаляет выбранный документ. 

  @param {string} uuid - уид документа
  @param {object} itemEl - объект с информацией о документа
  @param {number} itemIndex - индекс документа
  
  */

  const removeDocument = (uuid, itemEl, itemIndex) => {
    setValues({});

    if (itemEl && !uuid) {
      const newData = uploadData.filter((item) => item !== itemEl);

      setUploadData(newData);
    }

    if (uuid) {
      const newData = uploadData.filter((item) => item.uuid !== uuid);

      setUploadData(newData);

      deleteDocument({
        variables: {
          physicalPersonDocumentUuid: uuid,
        },
        skip: !uuid,
      });
    }
  };

  const addDocument = () => {
    setUploadData((prev) => [...prev, prev.length + 1]);
  };

  const handleUpload = (file) => {
    const formData = new FormData();
    formData.append("file", file);

    physicalDocumentUpload({
      variables: {
        actyonType: "",
        description: "",
        document: file,
        physicalPersonUuid: uuid,
      },
    });
  };

  const signatureDocumentHandler = (documentUuid) => {
    signatureDocument({
      variables: {
        documentUuid: documentUuid,
        physicalPersonUuid: uuid,
      },
      skip: !uuid,
    });
  };

  /**
  Функция renderFileLabel отображает название файла. который был загружен

  @param {number} index - индекс переданного документа
  @param {object} values - объект который хранит в себе имена загруженных файлов
  
  */

  const renderFileLabel = useCallback(
    (index) => {
      if (
        values &&
        values[`uploadPhysicalFile${index + 1}`] &&
        values[`uploadPhysicalFile${index + 1}`][0]?.name
      ) {
        return (
          <span
            className={styles.signatureContentSpan}
            onClick={() =>
              downloadFile(
                values[`uploadPhysicalFile${index + 1}`][0],
                values[`uploadPhysicalFile${index + 1}`][0]?.name
              )
            }
          >
            Скачать файл: {values[`uploadPhysicalFile${index + 1}`][0]?.name}
          </span>
        );
      }
    },
    [values]
  );

  useEffect(() => {
    if (documentsData) {
      setUploadData(documentsData);
    }
  }, [documentsData]);

  useEffect(() => {
    const socketLink = process.env.REACT_APP_API_BASE_URL_WS;
    const token = localStorage.getItem("accessToken");

    if (!socketLink || !token) {
      console.error(
        "WebSocket initialization failed: missing socketLink or token"
      );
      return;
    }

    const fullSocketLink = `${socketLink}?token=${token}`;
    const socket = new WebSocket(fullSocketLink);

    socket.onmessage = (event) => {
      try {
        const data = JSON.parse(event.data);

        if (data && data?.type === "document_change_state") {
          setUploadData((prev) => {
            return prev.map((item) => {
              if (item?.document && item?.document?.uuid === data?.uuid) {
                return {
                  ...item,
                  document: {
                    ...item.document,
                    documentSignature: {
                      ...item?.document?.documentSignature,
                      state: data.document_state,
                    },
                  },
                };
              } else {
                // Если документ не существует, вернуть пустой объект
                return item;
              }
            });
          });

          getSigFiles({
            variables: {
              id,
            },
          });
        }
      } catch (error) {
        console.error("Error parsing WebSocket message:", error);
      }
    };

    socket.onclose = () => {
      console.log("WebSocket connection closed");
    };

    socket.onerror = (error) => {
      console.error("WebSocket error:", error);
    };

    return () => {
      if (
        socket.readyState === WebSocket.OPEN ||
        socket.readyState === WebSocket.CONNECTING
      ) {
        socket.close();
      }
    };
  }, []);

  useEffect(() => {
    if (sigData && sigData?.physicalPerson?.personDocuments) {
      setUploadData(sigData?.physicalPerson?.personDocuments);
    }
  }, [sigData]);

  if (personLoading || signatureLoading || uploadLoading) {
    return <Preloader />;
  }

  return (
    <div>
      {signatureActive && (
        <Button
          variant="green"
          type="button"
          onClick={addDocument}
          className={styles.uploadBtn}
        >
          +
        </Button>
      )}

      {uploadData.map((item, index) => (
        <>
          {item?.document?.uuid ? (
            <div key={item?.document?.uuid} className={styles.fileFieldWrapper}>
              <div>
                {item?.document?.file && (
                  <span
                    className={styles.signatureContentSpan}
                    onClick={() =>
                      downloadFile(
                        item?.document?.presignedUrl,
                        item?.document?.file.split(".")[0]?.split("/")[2] +
                          "." +
                          item.document.file.split(".")[1]
                      )
                    }
                  >
                    Скачать файл:{" "}
                    {item?.document?.file.length < 30 ? (
                      item?.document?.file.split(".")[0]?.split("/")[2] +
                      "." +
                      item.document.file.split(".")[1]
                    ) : (
                      <abbr
                        title={
                          item?.document?.file.split(".")[0]?.split("/")[2] +
                          "." +
                          item.document.file.split(".")[1]
                        }
                      >
                        {item?.document?.file
                          .split(".")[0]
                          ?.split("/")[2]
                          .slice(0, 30) + "..."}
                      </abbr>
                    )}
                  </span>
                )}

                <Input
                  readOnly
                  variant="bordered"
                  placeholder={
                    item?.document?.title.length > 30
                      ? item?.document?.title?.slice(0, 30) + "..."
                      : item?.document?.title
                  }
                />
                {item?.document?.documentSignature?.state === "WAITING_SEND" ? (
                  <Button
                    variant="green"
                    type="button"
                    disabled={!signatureActive ? true : false}
                    className={styles.signatureBtn}
                    onClick={() => signatureDocumentHandler(item.document.uuid)}
                  >
                    Подписать
                  </Button>
                ) : null}
              </div>
              {!deleteLoading ? (
                <BasketIcon
                  onClick={() => removeDocument(item.uuid, item, index)}
                />
              ) : (
                <Preloader variant="small" />
              )}

              <SignatureStatus
                status={item?.document?.documentSignature?.state}
                className={styles.signatureSpan}
                preloaderClassName={styles.signatureStatus}
                fileUrl={
                  item?.document?.documentSignature?.presignedSignatureFile
                }
                fileName={
                  item?.document?.file.split(".")[0]?.split("/")[2] +
                  "." +
                  item.document.file.split(".")[1]
                }
              />
            </div>
          ) : (
            <div className={styles.uploadBox}>
              {renderFileLabel(index)}
              <FileField
                key={index}
                register={register}
                setValue={setValue}
                setValues={setValues}
                getValues={getValues}
                className={styles.uploadPlace}
                labelClassName={styles.uploadLabel}
                values={values}
                fileText={"Загрузить документ"}
                name={`uploadPhysicalFile${index + 1}`}
                handleUpload={() => {
                  handleUpload(watch(`uploadPhysicalFile${index + 1}`)?.[0]);
                }}
              />
              <BasketIcon
                onClick={() => {
                  removeDocument(item.uuid, item, index);
                }}
              />
            </div>
          )}
        </>
      ))}
    </div>
  );
};
