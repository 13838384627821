import { useCallback, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import cn from "classnames";
import { BaseFinderTemplate } from "../../../../../BaseFinderTemplate/BaseFinderTemplate";
import { Button } from "../../../../../Button";
import styles from "./EntityBaseFinder.module.scss";
import { useQuery } from "@apollo/client";
import { ALL_COMPANIES } from "../../../../../../services/queries/queries/companies/allCompaniesList";
import useFormatDocumentDate from "../../../../../../hooks/useFormatDocumentDate";
import { setParticipant } from "../../../../../../redux/slices/application";
import { Input } from "../../../../../Input";

const ListRow = ({ data, onChoose, choosenElId }) => {
  const rowInfo = useMemo(() => {
    return [
      {
        name: "ogrn",
        label: "ОГРН",
        value: data?.ogrn,
      },
      {
        name: "inn",
        label: "ИНН",
        value: data?.inn,
      },
      {
        name: "kpp",
        label: "КПП",
        value: data?.kpp,
      },
      {
        name: "address",
        label: "Адрес",
        value: data?.address?.value,
      },
    ];
  }, [data]);

  return (
    <li
      className={cn(styles.row, {
        [styles.active]: choosenElId === data?.id,
      })}
      onClick={() => onChoose(data?.id)}
    >
      <strong>{data?.fullName}</strong>
      {rowInfo.map((item) => (
        <div key={item?.name} className={styles.rowInfo}>
          <span>{item.label}</span>
          <span>{item.value}</span>
        </div>
      ))}
    </li>
  );
};


const EntityCard = ({ data, onChoose, choosenElId }) => {
  const [searchValue, setSearchValue] = useState('');
  const {formatDocumentDate} = useFormatDocumentDate()

  return (
    <div className={styles.entityCard}>
      <h2 className={styles.cardTitle}>Представитель</h2>

      {data?.node?.representatives?.length > 0 ? (
        <>
          <Input
            variant="bordered-green"
            value={searchValue}
            placeholder="Поиск среди представителей"
            setValue={setSearchValue}
            className={styles.cardInput}
          />
          <ul>
            {data?.node?.representatives?.map((item) => (
              <li
                key={item?.physicalPerson?.id}
                className={cn(styles.cardlistItem, {
                  [styles.active]: choosenElId === item?.physicalPerson?.id,
                })}
                onClick={() => onChoose(item?.physicalPerson?.id)}
              >
                <div className={styles.cardlistItemMain}>
                  <strong>{item?.physicalPerson?.lastName} {item?.physicalPerson?.firstName} {item?.physicalPerson?.patronymic}</strong>
                  <div className={cn(styles.grayText, styles.smallText)}>
                    <span>
                      {item?.physicalPerson?.email} {item?.physicalPerson?.phone}
                    </span>
                  </div>
                  <div className={cn(styles.grayText, styles.smallText)}>
                    <span>СНИЛС {item?.physicalPerson?.snils}</span>
                  </div>
                </div>

                <div className={cn(styles.smallText)}>
                  <span>
                    {item?.legalAuthorityRecord
                      ? `${item?.legalAuthorityRecord?.type?.name}  до ${formatDocumentDate(item?.legalAuthorityRecord?.durationStop)}`
                      : "Не загружен"}
                  </span>
                </div>
              </li>
            ))}
          </ul>
        </>
      ) : (
        <span>Нет представителей</span>
      )}
    </div>
  );
};

export const EntityBaseFinder = ({setShow, setData, cardId, setParticipantType, role}) => {
  const [choosenIndEntity, setChoosenEntity] = useState(null);
  const [search, setSearch] = useState('')
  const dispatch = useDispatch();
  const [choosenRepresentative, setChoosenRepresentative] = useState(null);

  const onAdd = useCallback(() => {
    const participant = {
      ...choosenIndEntity,
      id: cardId?.id,
      participantType: "LEGAL_PERSON",
      representative: choosenRepresentative,
      role: role,
    };

    setData && setData(participant);
    setParticipantType("LEGAL_PERSON");
    dispatch(setParticipant(participant));
    setShow(false);
  }, [choosenIndEntity, choosenRepresentative, setShow]);

  const { data } = useQuery(ALL_COMPANIES, {
		variables: {
			search: search,
      first: 10,
      offset: 0
		},
	},);

	const array = data?.allLegalPersons?.edges;

  const onChoose = useCallback((id) => {
    const entity = array?.find((item) => item?.node?.id === id);
    setChoosenEntity(entity);
  }, [array]);

  const onChooseRepresentative = useCallback(
    (id) => {
      
      const representative = choosenIndEntity?.node?.representatives?.find(
        (item) => item?.physicalPerson?.id === id
      );
      setChoosenRepresentative(representative);
    },
    [choosenIndEntity]
  );


  return (
    <BaseFinderTemplate
      inputLabel="Поиск по базе юрлиц"
      isEmpty={!search}
      emptyText="Начните вводить название организации, ИНН, КПП, ОГРН"
      setSearch={setSearch}
    >
      <div className={styles.full}>
        <div className={cn(styles.resultInner, styles.full)}>
          <ul className={styles.resultList}>
            {search && array?.map((item) => (
              <ListRow
                key={item?.node?.id}
                data={item?.node}
                onChoose={onChoose}
                choosenElId={choosenIndEntity?.node?.id}
              />
            ))}
          </ul>

          {choosenIndEntity && (
            <EntityCard
              data={choosenIndEntity}
              onChoose={onChooseRepresentative}
              choosenElId={choosenRepresentative?.physicalPerson?.id}
              set
            />
          )}
        </div>

        <Button variant="green" type="button" onClick={onAdd}>
          Сохранить
        </Button>
      </div>
    </BaseFinderTemplate>
  );
};
