/**
Функция unTransformDate превращает полученную с сервера дату в формате YYYY-MM-DD в формат DD.MM.YYYY

@param {string} date - дата
@returns {string | null} возвращает дату в формате DD.MM.YYYY или null

*/

export const unTransformDate = (date) => {
  if(date){
    const [year, month, day] = date.split("-");
    const formattedDate = `${day}.${month}.${year}`;
    return formattedDate;
  }

  return null
  
};