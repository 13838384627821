import { useEffect, useState } from "react";
import { Input } from "../../Input";
import { useDebouncedCallback } from "use-debounce";

/** 

При отправке запроса с query, содержащим значение, происходит фильтрация по этому значению, например ФИО. Если значение исчезло, уходит запрос с пустой переменной search и возвращаются все значения (т.е происходит сброс фильтра)

Пропсы:

@param {function} response - эндпоинт для передачи запроса. Эндпоинт может быть разным, в зависимости от выбранной вкладки на странице Базы

@param {string} className - передача дополнительных стилей из компонента где вызывается инпут

@param {string} placeholder - передача плейсхолдера в инпут

@param {function} debounce позволяет не отправлять запрос после каждого введенного символа, а только через 500 мс, после того как символы перестали набираться

@returns {ReactElement} - фильтрация контента на странице Базы
*/

export const FilteredSearch = ({
  response,
  className,
  placeholder,
  setCurrentPage,
  setFirst,
  query,
  setQuery
}) => {
 
  /**
  Функция debounce отправляет запрос из поисковой строки на сервер только спустя указанную задержку по времени

  @param {string} query - запрос из поисковой строки

  */

  const debounce = useDebouncedCallback((query) => {
    if (query !== "") {
      response({
        variables: {
          search: query,
        },
      });
    }
  }, 500);

  useEffect(() => {
    if (query) {
      setCurrentPage(0);
      setFirst(10);
    }
    debounce(query);
  }, [debounce, query]);

  return (
    <Input
      variant="green"
      placeholder={placeholder}
      className={className}
      value={query}
      setValue={setQuery}
      type="search"
    />
  );
};
