/**
  Функция downloadExistFiles позволяет скачать файл в браузере. Получив ссылку на файл, функция скачивает его с сервера, получая ответ как blob и после этого создает искусственную ссылку по которой уже и происходит скачивание в браузере

  @param {string} token - jwt токен для авторизации на сервере
  @param {string} url - ссылка для получения файла с сервера
  @param {string} name - имя файла, которое будет отображаться при скачивании в браузере
*/

export const downloadExistFiles = (token, url, name) => {
  fetch(url, {
    headers: {
      Authorization: `JWT ${token}`,
    },
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error("Непредвиденная ошибка");
      }
      return response.blob();
    })
    .then((blob) => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      const title = /[^/]*$/.exec(url)[0];
      a.href = url;
      a.download = name ? name : title;
      a.target = "_blank";
      a.rel = "noreferrer";
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    })
    .catch((error) => {
      console.error("Ошибка скачивания:", error);
    });
};