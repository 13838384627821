import { useEffect, useState } from "react";
import { SearchVariantsList } from "../../Lists";
import { useLazyQuery } from "@apollo/client";
import { SEARCH_REPRESENTATIVE } from "../../../services/queries/queries/companies/searchRepresentative";
import { useDebouncedCallback } from "use-debounce";
import { Input } from "../../Input";
import styles from "./BaseSearch.module.scss";

/** Компонент для поиска представителей юрлица по базе физлиц 
   Пропсы: 
    @param {array} targetArr - массив представитилей, в который будут добавлаться найденные физлица
    @param {function} setTargetArr - функция для изменения массива представителей
    @param {string} tooltipClassName - класс для стилизации всплывающей подсказки со списком найденных физлиц

   @returns {ReactElement} - поиск представителей юрлица по базе физлиц
*/

export const BaseSearch = ({ targetArr, setTargetArr, tooltipClassName }) => {
  const [query, setQuery] = useState("");
  const [searchData, setSearchData] = useState([]);
  const [searchRepresentative, { data }] = useLazyQuery(SEARCH_REPRESENTATIVE, {
    fetchPolicy: "no-cache",
  });

  /**
  Функция debounce отправляет запрос из поисковой строки на сервер только спустя указанную задержку по времени

  @param {string} query - запрос из поисковой строки

  
  */

  const debounce = useDebouncedCallback((query) => {
    if (query) {
      searchRepresentative({
        variables: {
          search: query
        },
      });
    }
  }, 500);

  useEffect(() => {
    debounce(query);
  }, [query, debounce]);

  useEffect(() => {
    if (data && data.allPhysicalPersons && data.allPhysicalPersons.edges) {
      setSearchData(data.allPhysicalPersons.edges);
    } else {
      setSearchData([]);
    }
  }, [data]);

  return (
    <div className="saerch">
      <Input
        variant="white-bg"
        placeholder={"Поиск по базе физлиц"}
        className={styles.globalSearch}
        value={query}
        setValue={setQuery}
        type="search"
      />
      {searchData && searchData.length > 0 && query.trim() !== "" && (
        <SearchVariantsList
          className={tooltipClassName}
          physicalData={searchData}
          results={targetArr}
          setSearchResults={setTargetArr}
          setQuery={setQuery}
        />
      )}
    </div>
  );
};
