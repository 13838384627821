import { useRef } from "react";
import { BasketIcon } from "../../../assets/icons/BasketIcon";
import styles from "./RepresentativeBox.module.scss";
import cn from "classnames";

/**
Обертка для информации о представителе в карточке физлица

Пропсы:
@param {ReactNode} children - любое содержимое для обертки
@param {boolean} isFull - флаг отвечающий за то, какой объем информации должна показывать обертка, всю или часть
@param {function} setIsFull - функция для изменения значения isFull
@param {function} handleRemove - функция, которая срабатывает при нажатии на кнопку удаления
@param {string} className - помогает передать дополнительные стили из родительского компонента
@returns {ReactElement} - возвращает стилизованную обертку с информацией о представителе

*/

export const RepresentativeBox = ({ children, isFull, setIsFull, handleRemove, className }) => {
  const boxRef = useRef(null)

  return (
    <div
      className={cn(styles.wrapper, className, {
        [styles.fullWrapper]: isFull,
      })}
      ref={boxRef}
    >
      {children}

      <button onClick={handleRemove}>
        <BasketIcon className={styles.removeItem}/>
      </button>
    </div>
  );
};
