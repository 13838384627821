/**
Функция downloadFile позволяет скачать ранее загруженный файл

@param {File} file - файл

*/

export const downloadFile = (file) => {
    const blob = new Blob([file], { type: file?.type || 'application/pdf' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = file?.name;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
};