import { useContext, useEffect, useRef } from "react";
import { CloseIcon } from "../../../assets/icons/CloseIcon";
import styles from "./ModalWindow.module.scss";
import Modal from "react-bootstrap/Modal";
import { Context } from "../../../App";

/**
Компонент модального окна

Пропсы:
@param {ReactElement} children - любой контент, который должен показываться в модальном окне
@param {string} className - дополнительне стили для стлизации модального она. Можно передать из компонента, где оно вызывается
@param {string} dialogClassName - дополнительные стили не для всего модального кона, а для контейнера с контентом. 
@param {string} modalTitle - строка, которая передается в компонент в том случае, если в модальном окне должен быть title
@param {string} size - размер модального кона, который будет показываться на экране
@param {function} setShowModal - функция, которая отвечает за отображение модального окна
@param {function} closeHandler - функция. которая срабатывает при закрытии модального окна
@returns {ReactElement} - модальное окно с переданным содержимым и кнопкой для закрытия

*/

export const ModalWindow = ({ children, className, dialogClassName, modalTitle, size, setShowModal, closeHandler, ...props }) => {

  const {showModal} = useContext(Context)
  const modalRef = useRef(null)

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (showModal && modalRef.current && !modalRef.current.contains(e.target)) {
        setShowModal(false)
      }
    }

    document.addEventListener("mousedown", checkIfClickedOutside)

    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside)
    }
  }, [showModal, setShowModal])

  return (
    <Modal
      {...props}
      size={size}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName={dialogClassName}
    >
      
      <Modal.Body className={className} ref={modalRef}>{children}</Modal.Body>
      <button className={styles.modalClose} onClick={ (e) => setShowModal(false)}>
        <CloseIcon />
      </button>
    </Modal>
  );
};
