/**
Функция  cleanObject  предназначена для очистки объекта от свойств, значения которых равны  null , пустой строке, или строке  "undefined-undefined-"

@param {object} obj - объект для очистки

@returns {object} - возвращает очищенный объект

*/


export const cleanObject = (obj) => {
  return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != null && v !== '' && v !== "undefined-undefined-"));
};