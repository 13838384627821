import { useEffect } from "react";
import { Select } from "../../../../components/Select/ui/Select";
import { useForm } from "react-hook-form";
import styles from "./ApplicationsSellerFilter.module.scss";

/**
Компонент для фильтрации заявок по продавцу

Пропсы:

@param {boolean} isClear - используется для очищения выбранного значения и сброса фильтра

@param {function} setDealParty - передача выбранного продавца

@param {function} setIsClear - функция для управления сбросом фильтра и очисткой выбранного значения

@returns {ReactElement} - Возвращает компонент Select
*/

export const ApplicationsSellerFilter = ({
  isClear,
  setDealParty,
  setIsClear,
  setCurrentPageFilter,
  setCurrentPage,
}) => {
  const currentSeller = sessionStorage.getItem("currentSeller");
  const { register, watch, resetField } = useForm({
    defaultValues: {
      sellers: currentSeller ? currentSeller : '',
    },
  });

  const select = watch("sellers");

  useEffect(() => {
    if (select !== '') {
      setDealParty(select);
      sessionStorage.setItem("currentSeller", select);
      setCurrentPageFilter(0);
      setCurrentPage(0);
    }

    if (select === "") {
      setDealParty("");
      sessionStorage.removeItem("currentSeller");
    }

    setIsClear(false);
  }, [select]);

  useEffect(() => {
    const currentSeller = sessionStorage.getItem("currentSeller");
    resetField("sellers", { defaultValue: currentSeller || "" });
  }, [isClear]);

  return (
    <Select
      isFormSelect
      name="sellers"
      variant="bordered-green"
      size="short"
      placeholder="Продавец"
      options={[
        { value: "UNISTROY", title: "Унистрой" },
        { value: "THIRD_PARTY", title: "Третьи лица" },
      ]}
      register={register}
      labelClassName={styles.sellersSelect}
    ></Select>
  );
};
