import { InfoList } from "../../../../InfoList";
import { useNavigate } from "react-router-dom";

/**
  Компонент выводит список документов клиента по сделке

  Пропсы:

  @param {string} className - помогает передать дополнительные стили для списка из родительского компонента
  @returns {ReactElement} - возвращает компонент Infolist (элемент списка) с информацией о документах клиента по сделке
*/

export const ClientDocumentsList = ({className, clientData}) => {

  const navigate = useNavigate()


  return(
    <>
      {clientData?.map((document, index) => (
      <InfoList key={index} className={className} onClick={() => navigate(`/application-documents/${index + 1}`, {
        state: {documents: clientData}
      })}>
        <h3>{document?.document?.documentType?.name ? document.document.documentType.name : document?.document ? document?.document?.title : document?.title}</h3>
        <span>{document?.state === 'COMPLETE' ? 'Подписан' : 'Не подписан'}</span>
      </InfoList>
    ))}
    </>
  )
}