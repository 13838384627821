/**
Функция для проверки объекта на на наличие определенных ключей и значений ключей

@param {object} obj - объект для проверки

*/

export const checkObjectFields = (obj) => {
  return Object.keys(obj).some(
    (key) =>
      key !== "signatureRelease" &&
      key !== "signaturesActive" &&
      obj[key] === null || obj[key] === '' || key !== "signatureRelease" &&
      key !== "signaturesActive" && obj[key].value === null
  );
};
