import { formatDate } from "../../../../helpers/formatDate";
import { translateStatus } from "../../../../helpers/translateStatus";
import styles from "./StatementStatuses.module.scss";
import { downloadExistFiles } from "../../../../helpers/downloadExistFile";
import { useEffect, useState } from "react";

export const StatementStatuses = ({ requestHistory, application, kuvd }) => {
  const accessToken = localStorage.getItem("accessToken");
  const [appUuid, setAppUuid] = useState('')
  const downLoadPackage = process.env.REACT_APP_PACKAGE_DOWNLOAD
  const fullLink = downLoadPackage + appUuid + '/download-zip-send/'

  useEffect(() => {
    if(application?.uuid){
      setAppUuid(application?.uuid)
    }
  }, [application])

  return (
    <div className={styles.statusesWrapper}>
      {requestHistory?.length > 0 && (
        <div className={styles.applicationStatusBlock}>
          <div>
            <h3 className={styles.application_headerText}>Статус заявления</h3>
            {requestHistory.map((item) => (
              <div>
                {formatDate(item.createdAt)} -{" "}
                {item?.statusDescription !== null
                  ? item?.statusDescription
                  : translateStatus(item.status, kuvd)}
                {item?.statusDescription === "Принято от заявителя" &&
                  ` Присвоен ${kuvd}`}
                {item?.statusDescription === "Получено от РР" && (
                  <span
                    className={styles.link}
                    onClick={() => {
                      downloadExistFiles(
                        accessToken,
                        fullLink
                      );
                    }}
                  >
                    {`скачать пакет`}
                  </span>
                )}
                {item?.status === "SEND_TO" && (
                  <span
                    onClick={() => {
                      downloadExistFiles(
                        accessToken,
                        fullLink
                      );
                    }}
                    className={styles.link}
                  >
                    {`скачать пакет`}
                  </span>
                )}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
