import { useState } from "react";
import styles from "./Sidebar.module.scss";
import cn from 'classnames'

/**
Компонент для вывода сайдбара

Пропсы:

@param {ReactNode} children - то, что будет отображаться внутри сайдбара

@param {ReactNode} ref - определение текущего файла в разметке при необходимости

@param {function} onClick - функция, которая может запускаться при клике на сайдбар

@param {string} className - дополнительные стили, которые могут передаваться из компонента, где вызывается сайдбар (строка)

@returns {ReactElement} - вовзвращает сайдбар с переданным содержимым

*/

export const Sidebar = ({children, ref, onClick, className}) => {
  

  return (
    <div
      className={cn(styles.sidebarWrapper, className)}
      ref={ref}
      onClick={onClick}
    >
      {children}
    </div>
  );
};
