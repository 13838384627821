import { useMemo, useRef } from "react";
import styles from "./RepresentativeItem.module.scss";
import cn from "classnames";
import { BasketIcon } from "../../../assets/icons/BasketIcon";
import useFormatDocumentDate from "../../../hooks/useFormatDocumentDate";

/**
Компонент для отображения информации о представителе

Пропсы:
@param {object} physicalRepresentative - объект с информацией о представителе-физлице
@param {function} physicalRepresentativeRemove - функция которая срабатывает при удалении представителя-физлица
@param {object} companyRepresentative - объект с информацией о представителе-компании
@param {function} companyRepresentativeRemove - функция. которая срабатывает при удалении представителя-компании
@param {object} physicalRepresented - объект с информацией о представляемом физлице
@param {function} physicalRepresentedRemove - функция, которая срабатывает при удалении представляемого физлица
@param {object} companyRepresented - объект с информацией о предствляемом юрлице
@param {function} companyRepresentedRemove - функция, которая срабатывает при удалении представляемого физлица
@param {string} className - помогает передать дополнительные стили для списка из родительского компонента
@param {string} id - id юрлица для которого отображаются представители и представляемые
@returns {ReactElement} - возвращает компонент с информацией о представителе или представляемом в зависимости от переданных данных

*/

export const RepresentativeItem = ({
  physicalRepresentative,
  physicalRepresentativeRemove,
  companyRepresentative,
  companyRepresentativeRemove,
  physicalRepresented,
  physicalRepresentedRemove,
  companyRepresented,
  companyRepresentedRemove,
  className,
  id,
}) => {
  const { formatDocumentDate } = useFormatDocumentDate();
  const boxRef = useRef(null);
  const itemVariantRender = useMemo(() => {
    if (
      physicalRepresentative &&
      Object.keys(physicalRepresentative).length > 0
    ) {
      return (
        <div className={cn(styles.wrapperBox, className)} ref={boxRef}>
          <div className={styles.infoWrapper}>
            <span>
              {physicalRepresentative?.physicalPerson?.lastName +
                " " +
                physicalRepresentative?.physicalPerson?.firstName +
                " " +
                physicalRepresentative?.physicalPerson?.patronymic}
            </span>
            <span className={styles.snils}>
              СНИЛС &nbsp;
              <span>{physicalRepresentative?.physicalPerson?.snils}</span>
            </span>
            <div className={cn(styles.documentTypeInfo)}>
              {physicalRepresentative?.legalAuthorityRecord?.type?.name ? (
                <span>
                  {physicalRepresentative?.legalAuthorityRecord?.type?.name}
                  {physicalRepresentative?.legalAuthorityRecord
                    ?.durationStop && (
                    <>
                      {" "}
                      до{" "}
                      {formatDocumentDate(
                        physicalRepresentative?.legalAuthorityRecord
                          ?.durationStop
                      )}
                    </>
                  )}
                </span>
              ) : (
                <span>Документ не загружен</span>
              )}
            </div>
          </div>
          {!id ? (
            <button
              onClick={(e) => {
                e.stopPropagation();
                physicalRepresentativeRemove();
              }}
              className={styles.removeItem}
            >
              <BasketIcon />
            </button>
          ) : null}
        </div>
      );
    }

    if (
      companyRepresentative &&
      Object.keys(companyRepresentative).length > 0
    ) {
      return (
        <div className={cn(styles.wrapperBox, className)} ref={boxRef}>
          <div className={styles.companyWrapper}>
            <span className={styles.companyTitle}>Юрлицо - представитель</span>
            <span>{companyRepresentative.fullName}</span>
            <span>{companyRepresentative.inn}</span>
          </div>
          {!id ? (
            <button onClick={companyRepresentativeRemove}>
              <BasketIcon className={styles.removeItem} />
            </button>
          ) : null}
        </div>
      );
    }

    if (physicalRepresented && Object.keys(physicalRepresented).length > 0) {
      return (
        <div className={cn(styles.wrapperBox, className)} ref={boxRef}>
          <div className={styles.companyWrapper}>
            <span className={styles.name}>
              {physicalRepresented.lastName +
                " " +
                physicalRepresented.lastName +
                " " +
                physicalRepresented.patronymic}
            </span>
            <div className={styles.documentsBox}>
              <span>СНИЛС</span>
              <span>{physicalRepresented.snils}</span>
            </div>
          </div>
          {!id ? (
            <button onClick={physicalRepresentedRemove}>
              <BasketIcon className={styles.removeItem} />
            </button>
          ) : null}
        </div>
      );
    }

    if (companyRepresented && Object.keys(companyRepresented).length > 0) {
      return (
        <div className={cn(styles.wrapperBox, className)} ref={boxRef}>
          <div className={styles.companyWrapper}>
            <span className={styles.companyTitle}>Представляемое юрлицо</span>
            <span>{companyRepresented.fullName}</span>
            <span>{companyRepresented.inn}</span>
          </div>
          {!id ? (
            <button onClick={companyRepresentedRemove}>
              <BasketIcon className={styles.removeItem} />
            </button>
          ) : null}
        </div>
      );
    }
  }, [
    physicalRepresentative,
    companyRepresentative,
    physicalRepresented,
    companyRepresented,
    className,
    formatDocumentDate,
    id,
    physicalRepresentativeRemove,
    companyRepresentativeRemove,
    physicalRepresentedRemove,
    companyRepresentedRemove,
  ]);

  return <>{itemVariantRender}</>;
};
