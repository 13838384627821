import styles from "./Button.module.scss";
import cn from "classnames";

/**
Компонент для создания кнопки

Пропсы:

@param {ReactNode} children - содержимое, которое будет отображаться на кнопке

@param {string} className - дополнительный класс, который можно передать из компонента где создается кнопка

@param {string} variant - вывод одного из заранее заданных вариантов оформления кнопки

@param {function} onClick - функция, которая выполняется при клике на кнопку, может передаваться из компонента, где создается кнопка

@param {string} type - выбор типа для кнопки (полезно при использовании в формах), например reset, submit, button

@param {boolean} disabled - отвечает за отключение кнопки. По умолчанию кнопка включена

@returns {ReactElement} возвращает кнопку с переданным содержимым

*/

export const Button = ({ children, className, variant, onClick, type, disabled = false, ...props }) => {
  return (
    <button
      className={cn(styles.btn, className, {
        [styles.green]: variant === "green",
        [styles.transparent]: variant === "transparent",
        [styles.extraLightGreen]: variant === "extra-light-green",
        [styles.red]: variant === 'red',
        [styles.white]: variant === 'white',
        [styles.outlineGreen]: variant === 'outline-green',
        [styles.transparentGreen]: variant === 'transparent-green',
        [styles.transparentTextGreen]: variant ==="transparent-green-text",
      })}
      onClick={onClick}
      type={type}
      disabled={disabled}
      {...props}
    >
      {children}
    </button>
  );
};
