import styles from "./TabsComponent.module.scss";
import { Tab, Tabs, TabList } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { useContext, useEffect, useState } from "react";
import { Context } from "../../../App";

/**
Компонент для отображения вкладок на странице Базы

Пропсы:
@param {array} data - данные для отрисовки вкладок
@param {ReactNode} children - содержимое вкладки
@returns {ReactElement} - возвращает вкладки с переданным содержимым

*/

export const TabsComponent = ({ data, children }) => {
  const { setTab, setTabIndex, tabIndex } = useContext(Context);

  useEffect(() => {
    switch (tabIndex) {
      case 0:
        setTab("Новое физлицо");
        setTabIndex(0);
        break;
      case 1:
        setTab("Новое юрлицо");
        setTabIndex(1);
        break;
      case 2:
        setTab("Новый представитель");
        setTabIndex(2);
        break;
      case 3:
        setTab("Новое юрлицо");
        setTabIndex(3);
        break;

      default:
    }
  }, [tabIndex, setTab, setTabIndex]);

  return (
    <Tabs className={styles.tabs} onSelect={(index) => setTabIndex(index)}>
      <TabList className={styles.tabsList}>
        {data.map((tab, index) => (
          <Tab key={index} className={styles.tab}>
            {tab}
          </Tab>
        ))}
      </TabList>
      {children}
    </Tabs>
  );
};
