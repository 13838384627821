import styles from "./DocumentViewer.module.scss";
import { Viewer, SpecialZoomLevel } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import { scrollModePlugin } from "@react-pdf-viewer/scroll-mode";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { Worker } from "@react-pdf-viewer/core";
import { ProgressBar } from "@react-pdf-viewer/core";
import { useState, useEffect } from "react";
import { ScrollMode } from "@react-pdf-viewer/core";
import { Preloader } from "../../Preloader";

/**
  Компонент для отображения PDF-файла, принимает на вход ссылку на файл приведенную к blob (см функцию getFile) или Uint8Array.

  В plugins можно добавлять плагины, предоставляющие дополнительные возможности (defaultLayoutPlugin по умолчанию).

  renderLoader отвечает за вывод прелоадера во время загрузки документа.

  Документация по библиотеке: https://react-pdf-viewer.dev/docs/options/

  @param {string} fileUrl - ссылка на файл, который нужно отобразить
  @returns {ReactElement} - возвращает компонент для просмотра PDF

*/

export const DocumentViewer = ({ fileUrl }) => {
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  const scrollModePluginInstance = scrollModePlugin();
  const [documentFile, setDocumentFile] = useState(null);
  const [loadingFile, setLoadingFile] = useState(false);
  const token = localStorage.getItem("accessToken");

  useEffect(() => {
    if (fileUrl) {
      const getFile = async () => {
        try {
          const response = await fetch(fileUrl, {
            headers: {
              Authorization: `JWT ${token}`,
              accept: "application/pdf",
            },
          });
          const blob = await response.blob();
          const url = URL.createObjectURL(blob);
          setDocumentFile(url);
          setLoadingFile(true);
        } catch (error) {
          console.log(error);
        }
      };

      getFile().finally(() => setLoadingFile(false));
    }
  }, [fileUrl, token]);

  return (
    <>
      {!loadingFile && documentFile ? (
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
          <Viewer
            fileUrl={documentFile}
            defaultScale={SpecialZoomLevel.PageFit}
            plugins={[defaultLayoutPluginInstance, scrollModePluginInstance]}
            scrollMode={ScrollMode.Vertical}
            renderLoader={(percentages) => (
              <div style={{ width: "240px" }}>
                <ProgressBar progress={Math.round(percentages)} />
              </div>
            )}
          />
        </Worker>
      ) : (
        <Preloader />
      )}
    </>
  );
};
