import styles from "./RadioButton.module.scss";
import cn from "classnames";


/**
Компонент радиокнопки, который позволяет пользователю выбрать один из предложенных вариантов

Пропсы:

@param {string} className - передача дополнительных стилей из компонента где вызывается радиокнопка

@param {string} name - имя под которым поле регистрируется в react-hook-form

@param {object} register - объект, для регистрации поля в react-hook-form, значение для передачи можно взять из хука useForm(), который предоставляет библиотека

@param {object} registerObj - объект для установки правил валидации поля (например обязательное поле) Пример: registerObj={{ required: "Поле обязательно для заполнения" }}

@param {string} variant - выбор определенной стилизации радиокнопки (строка)

@param {string} errorVariant - описать тому, кто создавал

@param {object} errors - объект с ошибками, предоставляемый библиоткекой react-hook-form (можно взять там же где и register). Если объект содержит ключ, совпадающий по названию с name, то для инпута с этим name выводится ошибка

@param {string} text1 - вариант ответа, который показывается возле радиокнопки

@param {string} text2 - вариант ответа, который показывается возле радиокнопки

*/
export const RadioButton = ({
  className,
  name,
  text1,
  text2,
  register,
  registerObj,
  errorVariant = "",
  errors,
  ...props
}) => {

  return (
    <div
      className={cn(className, {
        [styles.radioWrapper]: errors? !errors[name]: null,
        [styles.errorField]: errors && errors[name],
      })}
    >
      <label>
        <input
          type="radio"
          name={name}
          className={styles.radio}
          value={text1}
          {...register(name)}
          {...props}
        />
        <span className={cn(styles.radioSpan, styles.left)}>{text1}</span>
      </label>
      <label>
        <input
          type="radio"
          name={name}
          className={styles.radio}
          value={text2}
          {...register(name, registerObj)}
          {...props}
        />
        <span className={cn(styles.radioSpan, styles.right)}>{text2}</span>
      </label>
      {errors && errors[name] && (
        <p
          className={cn(styles.formError, {
            [styles.absolute]: errorVariant === "absolute",
          })}
        >
          {errors[name].message}
        </p>
      )}
    </div>
  );
};
